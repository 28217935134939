import "./newProduct.css";
import React, { useState  } from "react";
import { toast } from 'react-toastify';
  import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/footer/Footer";
import * as promoserv from "../../services/promoDetails";
import Topbar from "../../components/topbar/Topbar";
export default function NewProduct() {

  const [nom, setNom] = useState('');
  const [cles, setCles] = useState('');
  const [nombre, setNombre] = useState('');
  const [montant, setMontant] = useState('');
  const [expired, setExpired] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const data={
expired:expired,
cle:cles,nom:nom,
valeur:montant, nuse:nombre

    }
    console.log(data)
promoserv.create(data).then((rep)=>{
  if( rep && rep.length>1){
    toast.success('Code Promo creé avec success', {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.hash="#/products"
  }
  else {
    toast.error('Erreur de création! Veuillez reéssayer.', {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
})
    // You can add code here to submit the form data to your backend
  };

    


  return (
    <>
    <Topbar />

        
    
    <div className='container'>
    <Sidebar />
    <div className="newProduct">
      <h1 className="addProductTitle">Generer Promo</h1>
      <form className="addProductForm" onSubmit={handleSubmit}>
      <div className="addProductItem">
        <label>Nom :</label>
        <input type="text" placeholder="Nom promo" value={nom} onChange={(e) => setNom(e.target.value)} />
      </div>
      <div className="addProductItem">
        <label>Cle :</label>
        <input type="text" placeholder="Cle promo" value={cles} onChange={(e) => setCles(e.target.value)} maxLength={25} />
      </div>
      <div className="addProductItem">
        <label>Nombre d'utilisation : </label>
        <input type="number" placeholder="Nombre d'utilisateur" value={nombre} onChange={(e) => setNombre(e.target.value)} />
      </div>
      <div className="addProductItem">
        <label>Montant : </label>
        <input type="number" placeholder="Montant reduction" max={100} value={montant} onChange={(e) => setMontant(e.target.value)} />
      </div>
      <div className="addProductItem">
        <label>Expire le  : </label>
        <input type="date" placeholder="Expire le " max={100} value={expired} onChange={(e) => setExpired(e.target.value)} />
      </div>
      <button className="addProductButton" type="submit">Créer</button>
    </form>
    </div>

    </div>
    <ToastContainer />
    <Footer />
    </>
  );
}
