import "./newProduct.css";
import React, { useState,useEffect  } from "react";
import { toast } from 'react-toastify';
  import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/footer/Footer";
import * as promoserv from "../../services/userDetails";
import Topbar from "../../components/topbar/Topbar";
import { Link } from "react-router-dom";

export default function Email() {


  const url=window.location.href.split("/");
  const id=url[url.length-1]
  console.log(url[url.length-1]);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [sujet, setSujet] = useState('');
  const [date, setDate] = useState('');
  const [corps, setCorps] = useState('');
  const [usero, setData] = useState('');
  const [repo, setRepo] = useState('');


  useEffect(() => {
    async function fetchData() {
      try {
        const users = await promoserv.getmsgs({id:id});
        console.log(users);
        if(users && users.length>0){
          let msg= users[0];
          setCorps(msg.Corps)
          setSujet(msg.sujet)
          setDate(msg.date)
          setEmail(msg.email)
          if(msg.rep){
            setRepo(msg.rep)
          }
          if((sessionStorage.getItem('myData'))){
            const myData = sessionStorage.getItem('myData');
   setData(JSON.parse (myData))
          }
       /// setData(users);
      }
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data={
to:email,
id:id,
objet:sujet ,fpass:message,iduser:usero?.id

    }
    console.log(data)
    if(message.length>3){
promoserv.respo(data).then((rep)=>{
  console.log(rep)
  if( rep  ){
    toast.success('Réponse Envoyé avec success', {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.hash="#/email-liste"
  }
  else {
    toast.error('Erreur ! Veuillez reéssayer.', {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
})
} else {
  toast.error('Erreur ! Veuillez reéssayer.', {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
    // You can add code here to submit the form data to your backend
  };

    


  return (
    <>
    <Topbar />

        
    
    <div className='container'>
    <Sidebar />
    <div className="newProduct">
      <h1 className="addProductTitle">Reponse</h1>
      <form className="addProductForm" onSubmit={handleSubmit}>
      <div className="addProductItem">
        <label>Date :</label>
        <input type="text" readOnly placeholder="Date" value={date}    />
      </div>
     
      <div className="addProductItem">
        <label>Email :</label>
        <input type="text" readOnly placeholder="E-mail" value={email}    />
      </div>
      <div className="addProductItem">
        <label>Sujet :</label>
        <input type="text" readOnly placeholder="Sujet" value={sujet}    />
      </div>
      <div className="addProductItem">
        <label>Message :</label>
        <textarea type="text" placeholder="Reponse" value={corps} rows="6" readOnly   ></textarea>
      </div>
      {repo && repo.text && repo.text.length>0?
      <>
      <div className="addProductItem">
        <label>Reponse :</label>
        <textarea   readOnly  placeholder="Reponse"  value={ repo.created +': '+ repo.text} 
 rows="6"   >

       
        </textarea>
      </div>
       
    </>: 
      
      <>
      <div className="addProductItem">
        <label>Reponse :</label>
        <textarea   placeholder="Reponse" required value={message} rows="6" onChange={(e) => setMessage(e.target.value)} ></textarea>
      </div>
       
      <button className="addProductButton" type="submit">Répondre</button></>}
    </form> 
    <div>
            <Link to={"/email-liste"}>
              <button style={{float:'right'}}>Retour</button>
            </Link>
           
          </div>
    </div>
   
    </div>
    <ToastContainer />
    <Footer />
    </>
  );
}
