import React, { useState, useEffect  } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { userData } from "./../../dummyData";
import "./performanceChart.css";
import * as userserv from "../../services/userDetails"
 

export default function PerformanceChart() {
  const [data, setData] = useState(userData);
  useEffect(() => {
    async function fetchData() {
      try {
        const users = await userserv.getusers();
        if(users && users.length>0){
          users.map((ele) => {
            ele.pass='';
            const dateString = ele.created; // input date string in "Y-m-d" format
            const date = new Date(dateString);
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            ele.created=formattedDate

          })
          const countByDate = users.reduce((acc, item) => {
            const [day, month, year] = item.created.split('-');
            const monthYear = `${month}-${year}`;
            const index = acc.findIndex(el => el.name === monthYear);
            if (index !== -1) {
              acc[index].value += 1;
            } else {
              acc.push({name: monthYear, value: 1});
            }
            return acc;
          }, []);
          
          console.log(countByDate);
          
          ///const result = Object.keys(countByDate).map(key => ({ created: key, count: countByDate[key] }));
          
         
        setData(countByDate);
      }
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="parformanceChart">
      <div className="performanceChartTitle">
        <h3>Nouveaux inscrits par mois</h3>
      </div>
      <hr />
      <div className="parformanceChartGraph"  >
        <ResponsiveContainer width="90%" aspect={4 / 2.3}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="mototone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
