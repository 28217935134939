import * as  ApiService from "./apiService";
async function getall(  body ) {
    const apiObject = {};
      apiObject.method = "POST";
    
      apiObject.body=body;
      apiObject.endpoint = `getpromos.php`;
      const response = await ApiService.callApi(apiObject);
      return response;
  }  
  async function create(  body ) {
    const apiObject = {};
      apiObject.method = "POST";
    
      apiObject.body=body;
      apiObject.endpoint = `addpromo.php`;
      const response = await ApiService.callApi(apiObject);
      return response;
  }  
   
  export  {
   getall,create
  };
  