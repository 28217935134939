import * as  ApiService from "./apiService";


 
async function getusers( ) {
  const apiObject = {};
    apiObject.method = "POST";
    apiObject.body={"body":0};
    apiObject.endpoint = `getusr.php`;
    const response = await ApiService.callApi(apiObject);
    return response;
}  
 
async function getsuser(  body ) {
  const apiObject = {};
    apiObject.method = "POST";
  
    apiObject.body=body;
    apiObject.endpoint = `getusr.php`;
    const response = await ApiService.callApi(apiObject);
    return response;
}  

async function getmsgs(  body ) {
  const apiObject = {};
    apiObject.method = "POST";
  
    apiObject.body=body;
    apiObject.endpoint = `getmsgs.php`;
    const response = await ApiService.callApi(apiObject);
    return response;
}  
async function login(  body ) {
  const apiObject = {};
    apiObject.method = "POST";
  
    apiObject.body=body;
    apiObject.endpoint = `login.php`;
    const response = await ApiService.callApi(apiObject);
    return response;
}  
async function update(  body ) {
  const apiObject = {};
    apiObject.method = "POST";
  
    apiObject.body=body;
    apiObject.endpoint = `upuser.php`;
    const response = await ApiService.callApi(apiObject);
    return response;
} 
async function respo(  body ) {
  const apiObject = {};
    apiObject.method = "POST";
  
    apiObject.body=body;
    apiObject.endpoint = `sendresp.php`;
    const response = await ApiService.callApi(apiObject);
    return response;
} 
export  {
 getusers,getsuser,login,update,getmsgs,respo
};
