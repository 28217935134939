import React from 'react'
import "./footer.css"

export default function Footer() {
  return (
    <div className='footer'>
    <div className='footerTitle'>
      <br/>
      <br/>
    <h3>Check your prono </h3>
    <p>Tous droits réservés</p>

    <p>&copy; 2023</p>
    </div>
    </div>
  )
}
