import "./userList.css";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DeleteOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useState ,useEffect} from "react";
import * as React from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { CSVLink } from "react-csv";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/footer/Footer";
import Topbar from "../../components/topbar/Topbar";
import img from "./remove.png";
import img1 from "./add.png";
import * as userserv from "../../services/userDetails"

export default function UserList() {

  const entriesPerPage = 50;

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const users = await userserv.getusers();
        if(users && users.length>0){
          users.map((ele) => {
            ele.pass='';
            const dateString = ele.created; // input date string in "Y-m-d" format
            const date = new Date(dateString);
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            ele.created=formattedDate
            if(ele.abns && ele.abns.length>0){
              ele.abb='Oui'
            }else{
              ele.abb='Non'
            }

          })
          console.log(users);
        setData(users);}
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);
  
  
  
  const headers = [
    { label: "First Name", key: "prenom" },
    { label: "Last Name", key: "nom" },
    { label: "Email", key: "email" },
    { label: "Age", key: "code_pp" }
  ];
  const csvReport = {
    data: data,

  };


  const _export = React.useRef(null);
  const exportExport = () => {
    if (_export.current !== null) {
      _export.current.save(data);
    }
  };

  const handleDelete = (params) => {
    if (window.confirm("Voulez vous Modifier l'etat de Client ?")) {
      // User clicked OK, continue with the delete operation
      // ... 

     const act= params.active ==1?0:1;
      userserv.update({code:params.id,act:act}).then((rep)=>{
params.active=act
console.log(params)
      ///  setData(data.filter((item) => item.id !== id)); 
      });
    } else {
      // User clicked Cancel, do nothing
    }
   
  };
  const getRowClassName = (params) => {
    // Return the class name based on the row data
    if (params.row.active == 1) {
      return 'able'; // this is a CSS class name
    } else {
      return 'disable'; // this is a CSS class name
    }
  };
  const columns = [
    { field: "index", headerName: "ID", width: 100 },
    {
      field: "nom",
      headerName: "Nom",
      width: 160,
     renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" />
            {params.row.nom}
          </div>
        );
    }},
    {
      field: "prenom",
      headerName: "Prenom",
      width: 100
    },
    {
      field: "login",
      headerName: "Pseudo",
      width: 100
    },
    
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "abb",
      headerName: "Abonné",
      width: 120,
    },
    {
      field: "created",
      headerName: "Date Inscription",
      width: 120,
    },
    /*
    {
      field: "abonnement",
      headerName: "Abonnement",
      width: 120,
    }, 
    {
      field: "code_pp",
      headerName: "Code Promo",
      width: 160,
    },
     {
      field: "code_pr",
      headerName: "Code Parainnage",
      width: 160,
    },*/
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="action">
           {/* 
            <div>            <Link to={"/customer-edit/" + params.row.id}>
              <button className="userListEdit">Modifier</button>
            </Link>
            </div>
*/}
            <div
              className="userListDelete"
              
              onClick={() => handleDelete(params.row)}
            > {params.row.active ==1 ?
              <img src={img} height={30} alt=""/>
              : 
              <img src={img1} height={30}  alt=""/>
              }
              </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
    <Topbar />

        
    
    <div className='container'>
    <Sidebar />
    <div className="userList">
    <h1 className="userListTitle">Liste Clients </h1>
    <div className="userListTable">

      
    
   
    <CSVLink {...csvReport}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"

      > <button
      className="btn_export k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" >
       Exporter
     </button></CSVLink>
     
      <DataGrid className="userLists"
      
      data={data}
  
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={entriesPerPage} // Set the page size
        checkboxSelection
        getRowClassName={getRowClassName}
      />
      
  

      </div>
      </div>
      </div>
    <Footer />
    </>
  );
}
