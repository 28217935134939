import "./emailList.css";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import * as React from "react";
//import {  GridColumn, GridToolbar } from "@progress/kendo-react-grid";
//import { Grid } from '@progress/kendo-react-grid';
import * as userserv from "../../services/userDetails"
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/footer/Footer";

import Topbar from "../../components/topbar/Topbar";


export default function EmailList() {
  const entriesPerPage = 50;

  const getRowClassName = (params) => {
    // Return the class name based on the row data
    if (params.row.reponse == 1) {
      return 'able'; // this is a CSS class name
    } else {
      return 'disable'; // this is a CSS class name
    }
  };


  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const users = await userserv.getmsgs({dddd:0});
        console.log(users);
        if(users && users.length>0){
           
         
        setData(users);}
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);
  
  
  
  const headers = [
    { label: "First Name", key: "prenom" },
    { label: "Last Name", key: "nom" },
    { label: "Email", key: "email" },
    { label: "Age", key: "code_pp" }
  ];
  const csvReport = {
    data: data,

  };


  const _export = React.useRef(null);
  const exportExport = () => {
    if (_export.current !== null) {
      _export.current.save(data);
    }
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };
  
  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    {
      field: "sujet",
      headerName: "Objet",
      width:400,
     renderCell: (params) => {
        return (
          <div className="productListItem">
                      <img className="productListImg" src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" />
           {params.row.sujet}
          </div>
        );
    }},
    {
      field: "email",
      headerName: "Email",
      width: 200
    },
    {
      field: "date" ,
      headerName: "Date",
      width: 200
    },
    
    
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <>
          <div>
            <Link to={"/email/" + params.row.id}>
              <button className="userListEdit">Consulter / Repondre</button>
            </Link>
           
          </div>
           
         </>
        );
      },
    },
  ];

  return (
    <>
    <Topbar />

        
    
    <div className='container'>
    <Sidebar />
    <div className="userList">
    <h1 className="userListTitle">Liste des Demandes Clients </h1>
    <div className="userListTable">

      
  
      <DataGrid className="userLists"
      
      data={data}
  
        rows={data}
        disableSelectionOnClick
        columns={columns}
       pageSize={entriesPerPage} // Set the page size
        checkboxSelection

        getRowClassName={getRowClassName}
       
      />
      
   

      </div>
      </div>
      </div>
    <Footer />
    </>
  );
}
