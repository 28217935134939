import "./productList.css";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline } from "@mui/icons-material";

import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/footer/Footer";
import * as promoserv from "../../services/promoDetails";
import Topbar from "../../components/topbar/Topbar";

export default function ProductList() {
  const entriesPerPage = 50;
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        promoserv.getall({"ssds":0}).then((res)=>{
setData(res)
        });
      }
      catch(err){

      }
    } fetchData();
  }, [])
  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "nom",
      headerName: "Nom",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
           <img className="productListImg" src="https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" />
            {params.row.nom}
          </div>
        );
      },
    },

    {
      field: "code",
      headerName: "Code Promo",
      width: 120,
    },
    {
      field: "valeur",
      headerName: "Montant",
      width: 160,
    },
    {
      field: "expired",
      headerName: "Expire le ",
      width: 160,
    },
    {
      field: "nuse",
      headerName: "Nombre d'utilisation",
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/product/" + params.row.id}>
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
    <Topbar />

        
    
    <div className='container'>
    <Sidebar />
    <div className="productList">
        <h1 className="productListTitle">Liste Promo</h1>
      <div className="productListTable">
      <DataGrid className="productLists"
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={entriesPerPage} // Set the page size
    
        checkboxSelection
      />
      </div>
    </div>
    </div>
    <Footer />
    </>
  );
}
