import React,{useEffect,useState} from 'react'
import "./topbar.css";
import logo from "./images/logo.png"
import { PeopleAlt, NotificationsNone, AccountCircle } from '@mui/icons-material';
import * as promoserv from "../../services/userDetails";

import { Link } from "react-router-dom";

import { userRows } from "../../dummyData";

export default function Topbar() {

    const [usero, setData] = useState('');
    const [notifs, setNot] = useState('');
 
    useEffect(() => {
        async function fetchData() {
          try {
            const users = await promoserv.getmsgs({igggd:0});
            console.log(users);
            let ggg=0;
            if(users && users.length>0){
                users.map((ele)=>{
              let msg= ele;
              if(ele.reponse==0){
                ggg+=1;
              }
});
setNot(ggg)
             
              if((sessionStorage.getItem('myData'))){
                const myData = sessionStorage.getItem('myData');
       setData(JSON.parse (myData))
              }
           /// setData(users);
          }
          } catch (error) {
            console.log(error);
          }
        }
    
        fetchData();
      }, []);
    
    
      
        if (usero.type== "adm") then :
        return(<>
         <div className='topbar'>
        <div className='topbarWrapper'>
     
            <div className='topLeft'>
                <img className='logo' src={logo} alt="Logo"/>
            </div>
          
            <div className='topRight'>
                {/* <div className='topbarIconContainer'>
                    <Search className='icon' />
                </div> */}
                

                <Link to="/email-liste" className="link">
                <div className='topbarIconContainer'>
                    <NotificationsNone className='icon' />
                    <span className='topIconBadge'>{notifs}</span>
                </div>
                </Link>
            
                  
            </div>
        </div>
    </div>
         </>
        )
    






  return (
    <>
 {usero}
 </>
  )
}
